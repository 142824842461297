.App {
  text-align: center;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.game{
  background-color: #13244E;
  max-width: 600px;
  margin: auto;
    color: white;
}
.game-info{
  color: white;
  height: 20%;
}
.game-board {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
  align-items: center;
}



.carteimage {

  background: #fff;
  border: 1px solid #999;
  float: center;
  width: 30%;
  height: 30%;
  padding:1px;
  text-align: center;
}
.win{
  animation: App-logo-spin infinite 20s linear;
}
